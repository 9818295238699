
@if(customer){
  <app-licence-table class="margin-tab" [customer]="customer" [licences]="licences"></app-licence-table>
  <app-key-mnagement class="margin-tab" [customerIdInput]="customer.id!" (licenceUpdate)="licenceUpdate($event)"></app-key-mnagement>
}@else{
  <div class="column-100">
    <app-licence-table class="margin-tab" [licences]="licences"></app-licence-table>
    <app-key-mnagement class="margin-tab" (licenceUpdate)="licenceUpdate($event)"></app-key-mnagement>
  </div>
}

