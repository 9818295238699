export enum Roles {
  Admin = 3,
  AdminSV = 2,
  AdminSystem = 1,
  Demo = 6,
  User = 5,
  UserSV = 4
}

export enum KeyTypes {
  Imei = 'imei',
  Imsi = 'imsi',
  Udid = 'udid',
  UniqueId = 'unique_id'
}

export enum TabNames {
  Users = 'users',
  Licences = 'licences',
  Storage = 'storage'
}

export enum AuthorizedVersions {
  All = 'all',
  Android = 'android',
  AndroidEei = 'android_eei',
  Ios = 'ios',
  IosEei = 'ios_eei',
}

export enum PasswordModifType {
  Create = 'create',
  Change = 'change'
}


export const customerStatus: Map<number, string> =  new Map<number, string>([
  [1, 'customer'],
  [2, 'eval'],
  [3, 'inactive'],
  [4, 'internal'],
  [5, 'customer exception'],
  [6, 'EEI']
]);

export const customerCategory: Map<number, string> =  new Map<number, string>([
  [1, 'operator'],
  [2, 'media'],
  [3, 'manufacturer'],
  [4, 'Service company'],
  [5, 'Other']
]);

export const authorizedVersions: Map<number, string> =  new Map<number, string>([
  [1, 'customer'],
  [2, 'eval'],
  [3, 'inactive'],
  [4, 'internal'],
  [5, 'customer exception']
]);

export const uiKeyUpdate: string = 'Keys update';
export const uiKeyUpdateError: string = 'Error updating sv Keys';

export const authPages: string[] = ['Login', 'Change password', 'Create password'];

export const regexValidationPw: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<>=?@^_`|~{}[\]])[A-Za-z\d!"#$%&'()*+,-.\/:;<>=?@^_`|~{}]{11,}/;

/* Constant title connection history chart */
export const allAvailableData: string = 'all data available';
export const last14Days: string = 'last 14 days';
export const last12Weeks: string = 'last 12 weeks';
export const lastYear: string = 'last year';
export const last3Years: string = 'last 3 years';
export const connectionHistory: string = 'Connection history';

/* Constant text button connection history chart */
export const last14D: string = 'Last 14d';
export const last12W: string = 'Last 12w';
export const lastY: string = 'Last year';
export const last3Y: string = 'Last 3y';
export const all: string = 'All';


/* Constant type aggregate data */
export const days: string = 'days';
export const weeks: string = 'weeks';
