<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>
<div class="column-100">

  <div class="row-100">
    <div class="top-button">
      <button color="primary" mat-raised-button (click)="createCustomer()">Create Customer</button>
    </div>
    <mat-form-field appearance="fill" class="search-margin search-density">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
    </mat-form-field>
    <div class="top-button">
      <button color="primary" mat-raised-button (click)="extractUserCsv()">Extract User csv</button>
    </div>
  </div>

<div *ngIf="customersList" class="width-80">
  <table mat-table [dataSource]="customersList" matSort class="table-margin mat-elevation-z4 width-80">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let customer">
                {{customer.name}}
          </td>
      </ng-container>
      
      <!-- Active Column -->
      <ng-container matColumnDef="active" >
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let customer">
            <span *ngIf="!customer.isActive" class="icon-test-ko"></span>
            <span *ngIf="customer.isActive" class="icon-test-ok"></span>
      </ng-container>
      
      <!-- Status Column -->
      <ng-container matColumnDef="status" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let customer"> <span *ngIf="customer.status">{{statusList.get(customer.status)}}</span> </td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="category" >
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let customer"> <span *ngIf="customer.category">
          {{categoryList.get(customer.category)}}
        </span> </td>
      </ng-container>

      <!-- Mos Server Column -->
      <ng-container matColumnDef="Mos server" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mos server Id </th>
        <td mat-cell *matCellDef="let customer"> <ng-container *ngIf="customer.mosServer">{{ customer.mosServer.name }}</ng-container></td>
    </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Action" >
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let customer"><ul (click)="$event.stopPropagation()">
            <li *ngIf="isAdminSystem()">
              <button mat-icon-button (click)="deleteCustomer(customer)" matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
              </button>
            </li>
          </ul>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;let customer;" class="mat-row-artifacts"
          (click)="openSidebar(customer)"
          [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
      </tr>
  </table>
</div>