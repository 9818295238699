<div *ngIf="customer" class="column-80 customer-tab">
    <mat-checkbox color="primary" [(ngModel)]="customer.isActive">Status Active</mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="customer.status">
        <mat-option *ngFor="let recipient of statusList | keyvalue" [value]="recipient.key">
          {{recipient.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select [(ngModel)]="customer.category">
        <mat-option *ngFor="let recipient of categoryList | keyvalue" [value]="recipient.key">
          {{recipient.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Mos Server</mat-label>
      <mat-select [(ngModel)]="customer.mosServerId">
        <mat-option selected></mat-option>
        <mat-option *ngFor="let m of mosServerList" [value]="m.id">
          {{m.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

<button mat-raised-button color="primary" (click)="updateCustomer()" cdkFocusInitial>Update Customer</button>
</div>