<h1 mat-dialog-title>Customer</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameFormControl" [(ngModel)]="customer.name">
    </mat-form-field>
    @if(alreadyExists()){
      <mat-error>Customer name already exists</mat-error>
    }
    <mat-checkbox [(ngModel)]="customer.status">Status Active</mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="customer.status">
        <mat-option *ngFor="let recipient of statusList | keyvalue" [value]="recipient.key">
          {{recipient.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select [(ngModel)]="customer.category">
        <mat-option *ngFor="let recipient of categoryList | keyvalue" [value]="recipient.key">
          {{recipient.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Mos Server</mat-label>
      <mat-select [(ngModel)]="customer.mosServerId">
        <mat-option selected></mat-option>
        <mat-option *ngFor="let m of mosServerList" [value]="m.id">
          {{m.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">Cancel</button>
  <button mat-button [disabled]="nameFormControl.hasError('required') || alreadyExists()"  [mat-dialog-close]="customer" cdkFocusInitial>Ok</button>
</div>
