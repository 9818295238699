import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { 
  AxisLabelsFormatterContextObject, 
  PlotBarDataLabelsOptions, 
  SeriesBarOptions, 
  TooltipFormatterContextObject, 
  TooltipOptions, 
  YAxisLabelsOptions, 
  YAxisOptions 
} from 'highcharts';
import { ChartService } from 'src/app/utils/chart.service';
import { KpiUserService } from '../../services/kpi-user.service';
import { customerStatus } from 'src/app/utils/constants';
import { CookieService, User } from '@smartviser/core-lib';
import { UiService } from 'src/app/utils/ui.service';
import { DurationPipe } from 'src/app/utils/pipes/duration.pipe';
import { UsersService } from '../../services/users.service';
import { DateAdapter } from '@angular/material/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-user-kpi',
  templateUrl: './user-kpi.component.html',
  styleUrls: ['./user-kpi.component.less']
})
export class UserKpiComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;
  hg: typeof Highcharts = Highcharts;
  userChart: Highcharts.Options = {};
  dateToday: Date = new Date();
  range: UntypedFormGroup = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  statusFormControl: UntypedFormControl = new UntypedFormControl([1, 2]); 
  isLoading: boolean = true;
  totalHours: number = 0;
  totalCount: number = 0;
  userConnected!: User;
  customerStatus: Map<number, string> = customerStatus;

  constructor(
    private adminService: KpiUserService, 
    private chartService: ChartService, 
    private cookieService: CookieService, 
    private uiService: UiService,
    private durationPipe: DurationPipe,
    private usersService: UsersService,
    private dateAdapter: DateAdapter<Date>
  ){
  }

  ngOnInit(): void {
    this.userConnected = this.cookieService.getUserConnected() as User;
    const defaultFromDate: Date = new Date();
    defaultFromDate.setMonth(this.dateToday.getMonth() - 1);

    console.log(this.userConnected);

    console.log(this.customerIdInput);
    this.dateAdapter.getFirstDayOfWeek = () =>  1;
    this.range.setValue(
      {
        start: defaultFromDate, end: new Date()
      }
    );

    this.range.get('end')?.valueChanges.subscribe(() => this.refreshGraph());
    this.statusFormControl.valueChanges.subscribe(() => this.refreshGraph());
    this.refreshGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.customerIdInput = changes.customerIdInput.currentValue;
      console.log(this.customerIdInput);
      this.refreshGraph();
    }
  }


  refreshGraph = (): void => {
    const dateStart: Date|null = this.range.get('start')?.value as Date || null;
    const dateEnd: Date|null = this.range.get('end')?.value as Date || null;
    const status: string[]|null = this.statusFormControl.value as string[] || null;
    if(!!dateStart && !!dateEnd){
      if(!!status.length && (!this.usersService.isAdminSv() || (this.usersService.isAdminSv() && this.customerIdInput === 1))){
        this.isLoading = true;
        this.adminService.getUsersKpi(dateStart, dateEnd, status).subscribe({
          next: (kpi: Highcharts.Options) => {
            this.userChart = this.buildUserChart(kpi);
            this.computeTotal(kpi);
          },
          error: () => {
            this.uiService.openSnackBar('Error fetching KPI data', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }else if(!!status.length && this.usersService.isAdminSv()){
        this.isLoading = true;
        this.adminService.getUsersCustomerKpi(dateStart, dateEnd, this.customerIdInput.toString()).subscribe({
          next: (kpi: Highcharts.Options) => {
            this.userChart = this.buildUserChart(kpi);
            this.computeTotal(kpi);
          },
          error: () => {
            this.uiService.openSnackBar('Error fetching KPI data', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }else{
        this.uiService.openSnackBar('Please select a status and a date range', undefined);
      }
    }
  };

  computeTotal = (kpi: Highcharts.Options): void => {
    this.totalCount = 0;
    this.totalHours = 0;
    const hourSeries: SeriesBarOptions|null = kpi.series ? kpi.series[0] as unknown as SeriesBarOptions : null;
    const totalSeries: SeriesBarOptions|null = kpi.series ? kpi.series[1] as unknown as SeriesBarOptions : null;
    if(hourSeries && hourSeries.data){
      hourSeries.data.forEach((point: unknown) => {
        this.totalHours += (point as number);
      });
    }

    if(totalSeries && totalSeries.data){
      totalSeries.data.forEach((point: unknown) => {
        this.totalCount += (point as number);
      });
    }
  };

  buildUserChart = (options: Highcharts.Options): Highcharts.Options => {
    const yAxis: YAxisOptions[] = options.yAxis as YAxisOptions[];
    const labels: YAxisLabelsOptions = yAxis[0].labels as YAxisLabelsOptions || {};
    // to display correct Y Axis labels for datetime
    labels.formatter = (ctx: AxisLabelsFormatterContextObject): string => this.durationPipe.transform(+ctx.value, 'h m');
    yAxis[0].labels = labels;

    //to display correct value in bar label
    const series: SeriesBarOptions[] = options.series as SeriesBarOptions[];
    const label: PlotBarDataLabelsOptions = series[0].dataLabels as PlotBarDataLabelsOptions || {};
    const pipe: DurationPipe = this.durationPipe;
    // here we can't use arrow function because we need the chart context to get the value
    label.formatter = function(): string {
      return pipe.transform(+(this.y as number), 'h m');
    };
    series[0].dataLabels = label;

    //to display correct value in tooltip
    const tooltip: TooltipOptions = options.tooltip || {};
    tooltip.formatter = function(): string {
      const points: TooltipFormatterContextObject[] = this.points as TooltipFormatterContextObject[];
      const duration: number = points[0]?.y ?? 0; 
      const numberOfTests: number = points[1]?.y ?? 0;
      return `
        <b>${this.x}</b><br><br>
        <b>duration</b>: ${pipe.transform(duration, 'h m')}<br>
        <b># of tests</b>: ${numberOfTests}
      `;
    };


    return this.chartService.createMainChart(options);
  };

  displayCustomerStatus = (): boolean => this.userConnected && this.userConnected.roles <= 2 && this.customerIdInput === 1;
}
