import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'src/app/utils/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../../services/users.service';
import { Licence } from '../../../interfaces/licence.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ValidDialogComponent } from 'src/app/utils/shared-dialog/valid-dialog.component';
import { Customer } from '../../../interfaces/customer.interface';
import { CustomersService } from '../../../services/customers.service';
import { LicencesService } from 'src/app/admin/services/licences.service';
import { EditLicenceDialogComponent } from '../licence-management/edit-licence-dialog/edit-licence-dialog.component';

@Component({
  selector: 'app-licence-table',
  templateUrl: './licence-table.component.html',
  styleUrls: ['./licence-table.component.less']
})
export class LicencesTableComponent implements OnInit, OnChanges {
  @Input() customer!: Customer;
  @Input() licences!: Licence[];
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumnsAdmin: string[] = [
    'select',
    'id',
    'startDate',
    'endDate',
    'synchroviser',
    'proxy',
    'mos',
    'auxCall',
    'authorizedVersions',
    'key',
    'actions'
  ];

  displayedColumns: string[] = [
    'id',
    'startDate',
    'endDate',
    'synchroviser',
    'proxy',
    'mos',
    'auxCall',
    'authorizedVersions',
    'key'
  ];

  licencesList: MatTableDataSource<Licence> = new MatTableDataSource<Licence>([]);
  isLoading: boolean = true;
  customerId!: number;
  search: string = '';
  timezoneFr: any = { timeZone: 'Europe/Paris' };
  
  selection: SelectionModel<Licence> = new SelectionModel<Licence>(true, []);

  constructor(
    public licencesServices: LicencesService,
    public customerService: CustomersService,
    private usersService: UsersService,
    private uiService: UiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // If AdminSv or AdminSystem display User of customer instead dispaly user of the customer
    if(this.customer){
      if (this.customer.id) {
        this.customerId = this.customer.id;
      } else {
        this.customerId = this.usersService.getCustomerId();
      }
      if(this.customer.id !== this.customerId){
        this._setLicenceCustomer();
      }
    }else{
      this.customerId = this.usersService.getCustomerId();
      this._setLicenceCustomer();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer) {
      this.licencesList.data = [];
      this.customer = changes.customer.currentValue;
      this.customerId = this.customer.id as number;
      this._setLicenceCustomer();
      this.search = '';
    }
    if(changes.licences && changes.licences.currentValue){
      this.licencesList = new MatTableDataSource(changes.licences.currentValue);
      this.licencesList.sort = this.sort;
      this.licencesList.paginator = this.paginator;
    }
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.licencesList.filter = filterValue.trim().toLowerCase();
  }

  createLicence(): void {
    const dialogRef: MatDialogRef<EditLicenceDialogComponent> = this.dialog.open(
      EditLicenceDialogComponent,
      {
        minWidth: 600,
        data: { customerId: this.customerId, isEdit: false }
      }
    );
    dialogRef.afterClosed().subscribe((licenceAdd: {licence: Licence; nbLicence: number}) => {
      if (licenceAdd) {
        this.isLoading = true;
        this.licencesServices.createLicences(this.customerId, licenceAdd.licence, licenceAdd.nbLicence).subscribe({
          next: () => {
            this.uiService.openSnackBar('licences added', undefined);
            this._setLicenceCustomer();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  isAdminSv = (): boolean => this.usersService.isAdminSv();

  isLicenceExpired = (date: string): boolean => new Date(date) < new Date();

  updateTable(): void {
    this._setLicenceCustomer();
  }

  _setLicenceCustomer(): void {
    this.isLoading = true;
    this.selection.clear();
    this.licencesServices.getLicences(this.customerId).subscribe({
      next: (licences: Licence[]) => {
        this.licencesServices.setNbLicence(licences.length);
        this.licencesList = new MatTableDataSource(licences);
        this.licencesList.sort = this.sort;
        this.licencesList.paginator = this.paginator;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.uiService.openSnackBar('Error fetching users', undefined);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.licencesList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.licencesList.data.forEach((row: Licence) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Licence): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }


  deleteLicence(licences: Licence): void {
    const dialogRef: MatDialogRef<ValidDialogComponent> =  this.dialog.open(ValidDialogComponent, {
      minWidth: 600
    });
    dialogRef.afterClosed().subscribe((shouldDelete: boolean) => {
      if(shouldDelete){
        this.isLoading = true;
        this.licencesServices.deleteLicence(licences.id as number).subscribe({
          next: () => {
            this.uiService.openSnackBar('Licences deleted', undefined);
            this._setLicenceCustomer();
          },
          error: () => {
            this.isLoading = false;
            this.uiService.openSnackBar('Error deleting licences', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  ediLicenceDialog(licence: Licence): void {
    const copyLicence: Licence = JSON.parse(JSON.stringify(licence));
    const dialogRef: MatDialogRef<EditLicenceDialogComponent> = this.dialog.open(
      EditLicenceDialogComponent,
      {
        minWidth: 600,
        data: { licence: copyLicence, customerId: this.customerId, isEdit: true }
      }
    );
    dialogRef.afterClosed().subscribe((licenceEdit: {licence: Licence}) => {
      if (licenceEdit) {
        if(licenceEdit.licence.startDate instanceof Date){
          licenceEdit.licence.startDate = licenceEdit.licence.startDate.toLocaleDateString();
        }
        if(licenceEdit.licence.endDate instanceof Date){
          licenceEdit.licence.endDate = licenceEdit.licence.endDate.toLocaleDateString();
        }
        this.licencesServices.updateLicence(this.customerId, licenceEdit.licence).subscribe({
          next: () => {
            this.uiService.openSnackBar('licence updated', undefined);
            this._setLicenceCustomer();
          },
          error: () => {
            this.isLoading = false;
            this.uiService.openSnackBar('Error updating licence', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  editMultipleLicences(): void {
    const dialogRef: MatDialogRef<EditLicenceDialogComponent> = this.dialog.open(
      EditLicenceDialogComponent,
      {
        minWidth: 600,
        data: { customerId: this.customerId, isEdit: true, licences: this.selection.selected}
      }
    );
    dialogRef.afterClosed().subscribe((licenceToEdit: {licence: Licence; nbLicence: number}) => {
      const licenceEdit: Licence = licenceToEdit.licence;
      if (licenceEdit) {
        // Update licence one by one to not update start date
        let licenceEndDate: string = '';
        if(licenceEdit.endDate instanceof Date){
          licenceEndDate = licenceEdit.endDate.toLocaleDateString();
        }
        this.selection.selected.forEach((licence: Licence) => {
          licence.endDate = licenceEndDate;
          licence.startDate = new Date(licence.startDate).toLocaleDateString();
          licence.id = licence.id;
          licence.proxy = licenceEdit.proxy;
          licence.synchroviser = licenceEdit.synchroviser;
          licence.auxCall = licenceEdit.auxCall;
          licence.mos = licenceEdit.mos;
          this.licencesServices.updateLicence(this.customerId, licence).subscribe({
            next: () => {
              this.uiService.openSnackBar('licence updated', undefined);
              this._setLicenceCustomer();
            },
            error: () => {
              this.isLoading = false;
              this.uiService.openSnackBar('Error updating licence', undefined);
            },
            complete: () => {
              this.isLoading = false;
            }
          });
        });
      }
    });
  }

}
